export const socialsData = {
    github: 'https://github.com/decoded-cipher',
    behance: 'https://www.behance.net/Decoded_Cipher',
    linkedIn: 'https://linkedin.com/in/-arjunkrishna/',
    instagram: 'https://www.instagram.com/decoded_cipher/',
    // codepen: 'https://codepen.io/',
    twitter: 'https://twitter.com/decoded_cipher',
    // reddit: 'https://www.reddit.com/user/',
    // blogger: 'https://www.blogger.com/',
    medium: 'https://medium.com/@decoded_cipher',
    // stackOverflow: 'https://stackoverflow.com/users/11622116/decoded-cipher',
    gitlab: 'https://gitlab.com/decoded-cipher',
    // youtube: 'https://youtube.com/'
}